import React from "react"
import styled from "styled-components"
import { styles } from "."

export function QuickInfoTitle({ title }) {
  return (
    <QuickInfoTitleWrapper>
      <h3>{title}</h3>
    </QuickInfoTitleWrapper>
  )
}
QuickInfoTitle.defaultProps = {
  title: "Our Title",
}

const QuickInfoTitleWrapper = styled.div`
  text-align: center;
  h3 {
    ${styles.textQuickInfoTitle};
    color: ${styles.colors.mainOrange};
    text-shadow: 0.02em 0.02em black;
    text-decoration-line: underline;
  }
  h1 {
    ${styles.letterSpacing({ spacing: "0.3rem" })};
    font-size: 2rem;
    text-transform: uppercase;
  }
  .underline {
    width: 5rem;
    height: 0.2rem;
    background: ${styles.colors.mainYellow};
    margin: 0.5rem auto;
  }
`
