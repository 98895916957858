import React, { Component } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { styles } from "../../utils"
import logo from "../../images/traditions-logo.svg"

const year = () => new Date().getFullYear()

export default class Footer extends Component {
  state = {
    social: [
      {
        id: 1,
        text: `Facebook`,
        path: `https://www.facebook.com/TraditionsOnTheLake/`,
      },
      {
        id: 2,
        text: `Twitter`,
        path: `https://twitter.com/TraditionsGrill/`,
      },
      {
        id: 3,
        text: `Instagram`,
        path: `https://www.instagram.com/traditionsonthelake/`,
      },
    ],
  }
  render() {
    return (
      <FooterWrapper>
        <div className="container footer">
          <div className="row justify-content-center">
            <div className="col-xl-2 col-sm-3 col-4 mt-4 mb-3 order-2 order-md-1 align-self-center">
              <h5>Links</h5>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about/">About</Link>
              </li>
              <li>
                <Link to="/catering/">Catering</Link>
              </li>
            </div>
            <div className="col-xl-5 col-md-6 col-sm-12 pb-3 order-1 order-md-2">
              <img
                src={logo}
                alt="Traditions on the Lake"
                className="footer-logo"
                style={{ margin: "-3.5rem 0 0.5rem 0" }}
              />

              <hr />
              <h4><a href="tel:864-722-2800" rel="noopener noreferrer">864-722-2800</a></h4>
              <h4>13500 Clemson Blvd.</h4>
              <h4>Seneca, SC 29678</h4>
            </div>

            <div className="col-xl-2 col-sm-3 col-4 mt-4 mb-3 order-3 align-self-center">
              <h5>Friends</h5>
              {this.state.social.map(item => (
                <li key={item.id}>
                  <a href={item.path} target="_blank" rel="noopener noreferrer">
                    {item.text}
                  </a>
                </li>
              ))}
            </div>
          </div>
        </div>
        <div align="center" className="copyright">
          &copy; {`${year()}`} Traditions on the Lake
        </div>
      </FooterWrapper>
    )
  }
}
const FooterWrapper = styled.footer`
  background-color: ${styles.colors.lightGrey};
  padding: 2rem 0 0 0;
  .icons {
    /* width: 10rem; */
    display: flex;
    justify-content: space-between;
  }
  .icon {
    color: ${styles.colors.mainWhite};
    font-size: 1.3rem;
    ${styles.transition({})};
    &:hover {
      color: ${styles.colors.mainWhite};
    }
  }
  .copyright {
    background-color: ${styles.colors.primaryColor};
    margin-top: 1rem;
    padding-top: 0.5%;
    color: ${styles.colors.mainWhite};
  }
  .title {
    text-align: center;
    width: 10rem;
    color: ${styles.colors.mainWhite};
  }
  /* From Craft */
  /* Footer */
  .footer {
    background-color: ${styles.colors.lightGrey};
    color: ${styles.colors.darkGrey};
    text-align: center;
    ${styles.textDefault}
  }
  .footer a {
    color: inherit;
    background-color: none;
    
  }
  .footer .phone-number a {
    background-color: transparent;
  }
  .footer .addr-link {
    font-size: 0.9em;
    line-height: 0.9em;
  }
  .footer .addr-link:hover,
  .footer a {
    text-decoration: none;
  }
  .footer h4 {
    font-size: 1.75em;
    line-height: 1.5rem;
  }
  #footer-name {
    color: ${styles.colors.primaryColor};
  }
  .footer h5 {
    font-size: 2em;
    color: ${styles.colors.darkGrey};
    text-decoration: underline;
    text-decoration-color: ${styles.colors.primaryColor};
  }
  .footer h6 {
    font-family: sans-serif;
    font-weight: 200;
    color: #e05a5a;
    font-size: 0.95em;
  }
  .footer .name {
    font-family: "TheGoldsmithVintage", serif;
    font-size: 2em;
    color: #263746;
  }
  .footer .name:hover {
    cursor: default;
  }
  .footer hr {
    background-color: #263746;
    width: 6rem;
  }
  .footer img.footer-logo {
    width: 14rem;
    height: auto;
    margin-top: -1.5rem;
    margin-bottom: 1rem;
    transition-duration: 0.75s;
  }
  .footer img.footer-logo:hover {
    transform: rotate(360deg);
    transition-duration: 1s;
  }
  .footer .bottom-bar {
    background-color: ${styles.colors.darkGrey};
    padding-top: 0.5%;
    color: ${styles.colors.lightGrey};
  }
  .footer li {
    list-style-type: none;
    text-align: center;
    font-size: 1.35em;
    letter-spacing: -0.05em;
    transition-duration: 0.25s;
  }
  .footer li a {
    background-color: transparent;
    border-bottom: 0;
  }
  .footer li a:hover {
    text-decoration: none;
    text-decoration-color: #263746;
    transition-duration: 0.25s;
  }
  .footer li a:hover::before {
    content: "•";
    color: #263746;
  }
  .footer li a:hover::after {
    content: "•";
    color: #263746;
    transition-duration: 0.5s;
  }
  .footer li:nth-child(even) {
    background-color: ${styles.colors.mediumGrey};
    mix-blend-mode: darken;
  }
  .footer li:hover {
    transform: scale(1.1, 1.1);
    transition-duration: 0.25s;
  }
`
