import styled from "styled-components"
import React from "react"
import img from "../images/bcg/lakeside-patio-restaurant-in-clemson-sc.jpg"

// function based component
// pass down props to this component ({img and children})
function HomeHeader({ img, children }) {
  return <IndexHeader img={img}>{children}</IndexHeader>
}
function PageHeader({ img, children }) {
  return <IndexHeader img={img}>{children}</IndexHeader>
}
function AboutHeader({ img, children }) {
  return <BlendHeader img={img}>{children}</BlendHeader>
}
function CateringHeader({ img, children }) {
  return <OpaqueHeader img={img}>{children}</OpaqueHeader>
}

const IndexHeader = styled.header`
  min-height: calc(100vh - 88.78px);
  background-image: radial-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.55)), url(${props => props.img});
  background-repeat: no-repeat; 
  background-position: center;
  background-attachment: local;       
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height:100%;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  min-height: 80vh;
  @media (max-width: 992px) {
    background-attachment: initial;
  }
`

const BlendHeader = styled(IndexHeader)`
  background-image: radial-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.95)), url(${props => props.img});
  h1 {
    padding: 3rem 0 3rem 0;
  }
`

const TanHeader = styled(IndexHeader)`
  background-color: #947e61;

  h1 {
    padding: 3rem 0 3rem 0;
  }
`
const OpaqueHeader = styled(IndexHeader)`
  background-image: url(${props => props.img}), linear-gradient(180deg,#bdc3c7, #2c3e50);
  background-blend-mode: darken; 

  h1 {
    padding: 3rem 0 3rem 0;
  }
`

// fallback default images
HomeHeader.defaultProps = {
  img: img,
}
PageHeader.defaultProps = {
  img: img,
}

export { HomeHeader, PageHeader, AboutHeader, TanHeader, CateringHeader }
