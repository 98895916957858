export const colors = {
  primaryColor: `#263746`,
  secondaryColor: `#4296b4`,
  mainPurple: `#9369a4`,
  mainWhite: `#fff`,
  navLinkWhite: `rgba(255,255,255,.5);`,
  mainBlack: `#262626`,
  mainYellow: `#d2aa5c`,
  mainTan: `tan`,
  mainOrange: `#ff9500`,
  darkBrown: `rgba(112, 90, 74, .6)`,
  mainYellow2: `#F2AF29`,
  lightGrey: `#e2e9ef`,
  mediumGrey: `#929ba2`,
  darkGrey: `#474747`,
}
export const textDefault = `font-family: lexia-400, sans-serif;`
export const textSlanted = `font-family: balboa-300, sans-serif;`
export const textNavLink = `font: normal 1.8em/1em lexia-400, sans-serif;`
export const textBannerTitle = `font: normal 6em/1.25em balboa-300, sans-serif;`
export const textBannerSubTitle = `font: normal 2.75em/1em lexia-800, sans-serif;`
export const textQuickInfoTitle = `font: normal 2em balboa-300, sans-serif;`

export const transDefault = "transition:all 0.2s ease-in-out"
export const transFunction = (
  property = "all",
  time = "0.3s",
  type = "linear"
) => {
  return `transition:${property} ${time} ${type}`
}
export const transObject = ({
  property = "all",
  time = "0.3s",
  type = "ease-in-out",
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const transition = ({
  property = "all",
  time = "0.3s",
  type = "ease-in-out",
}) => {
  return `transition: ${property} ${time} ${type}`
}

export const border = ({
  width = "0.15rem",
  type = "solid",
  color = "white",
}) => {
  return `border:${width} ${type} ${color}`
}

export const letterSpacing = ({ spacing = "0.1rem" }) => {
  return `letter-spacing:${spacing}`
}
