import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import Navbar from "../components/globals/navbar"
import Footer from "../components/globals/Footer"
import Newsletter from "../components/globals/Newsletter"

import "./bootstrap.min.css"
import "../sass/layout.scss"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Navbar />
      {children}
      <Newsletter />
      <Footer />
    </React.Fragment>
  )
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family:"lexia-400";
    src:url("https://use.typekit.net/af/241dff/00000000000000003b9b402f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/241dff/00000000000000003b9b402f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/241dff/00000000000000003b9b402f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
  }

  @font-face {
    font-family:"lexia-800";
    src:url("https://use.typekit.net/af/de1100/00000000000000003b9b4033/27/l?subset_id=2&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/de1100/00000000000000003b9b4033/27/d?subset_id=2&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/de1100/00000000000000003b9b4033/27/a?subset_id=2&fvd=n8&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:800;
  }

  @font-face {
    font-family:"balboa-500";
    src:url("https://use.typekit.net/af/5133f9/00000000000000003b9b461e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/5133f9/00000000000000003b9b461e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/5133f9/00000000000000003b9b461e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;
  }

  @font-face {
    font-family: "balboa-300";
    src:url("https://use.typekit.net/af/ebb031/00000000000000003b9b461d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/ebb031/00000000000000003b9b461d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/ebb031/00000000000000003b9b461d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;
  }

  html {
    width: 100%;
  }
  body {
    width: 100%;
    font-family: "lexia-400";
  }
  h1, h2 {
    font-family: "balboa-500";
  }
  h3, h4, h5 {
    font-family: "lexia-800";
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
