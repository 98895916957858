import React, { Component } from "react"

export default class NewsletterSignUpForm extends Component {
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://traditionsonthelake.activehosted.com/f/embed.php?id=1';
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="_form_1" />
    )
  }
}
