import React, { Component } from "react"
import styled from "styled-components"
import NewsletterSignUpForm from "./NewsletterSignUpForm"
import { styles } from "../../utils"

export default class Newsletter extends Component {
  render() {
    return (
      <NewsletterWrapper>
        <div className="container newsletter" align="center">
          <h3>Hey You...</h3>
          <h4>Join Our Newsletter!</h4>
          <div className="row px-0 py-5 justify-content-center" >
            <NewsletterSignUpForm></NewsletterSignUpForm>
          </div>
        </div>
      </NewsletterWrapper>
    )
  }
}
const NewsletterWrapper = styled.section`
  background-color: ${styles.colors.primaryColor};
  padding: 2rem 0;
  text-align: center;
  .newsletter {
    /* width: 80vw; */
  }
  .newsletter h3 {
    ${styles.textQuickInfoTitle};
    text-transform: uppercase;
    color: ${styles.colors.mainOrange};
    text-shadow: 0.02em 0.02em black;
    font-size: 2.5rem;
    color: ${styles.colors.mainOrange};
    font-size: 2em;
    text-decoration: none;
    /* transform: scale(1);
    transition-duration: 0.5s; */
  }
  .newsletter h4 {
    ${styles.textBannerSubTitle};
    color: ${styles.colors.mainWhite};
    font-size: 3.5rem;
    text-shadow: 0.025em 0.025em black;
    color: white;
    /* transform: scale(1) rotate(0deg);
    transition-duration: 0.5s; */
  }

  .newsletter:hover h3 {
    /* transform: scale(0.75);
    transition-duration: 0.5s; */
  }

  .newsletter:hover h4 {
    /* transform: scale(1.2) rotate(-3.5deg); */
    text-shadow: 0.03em 0.03em black;
    /* transition-duration: 0.5s; */
  }
  div.newsletter ._form_1 {
    max-width: 100% !important;
  }
  @media (max-width: 768px) {
    .newsletter h3 {
      font-size: 1.75rem;
    }
    .newsletter h4 {
      font-size: 2.2rem;
    }
  }
`
