// class based component rcc
import React, { Component } from "react"
import NavbarHeader from "./NavbarHeader"
import NavbarLinks from "./NavbarLinks"
import NavbarIcons from "./NavbarIcons"
import { styles } from "../../../utils"
import styled from "styled-components"

export default class Navbar extends Component {
  state = {
    // only one property navbarOpen
    navbarOpen: false,
  }

  // this method controls the state of the navbar
  handleNavbar = () => {
    this.setState(() => {
      return { navbarOpen: !this.state.navbarOpen }
    })
  }

  // we want to pass the handleNavbar method down to header
  // and navbarOpen to links
  render() {
    return (
      <NavWrapper>
        <NavbarHeader handleNavbar={this.handleNavbar} />
        <NavbarLinks navbarOpen={this.state.navbarOpen} />
        <NavbarIcons />
      </NavWrapper>
    )
  }
}

const NavWrapper = styled.nav`
  background-color: ${styles.colors.primaryColor};
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`
